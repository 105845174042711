import { useTranslation } from "react-i18next";
import { Address } from "../__generated__/graphql";
import { Button } from "./Button";
import Input from "../components/styled/input";
import { Select } from "../components/styled/select";
import { getData, getName } from "country-list";
import { forwardRef, useImperativeHandle, useState } from "react";
import clone from "clone";

const AddressComponent = ({ addressString }: { addressString: string }) => {
  return (
    <address>
      <span dangerouslySetInnerHTML={{ __html: addressString.replace(/(?:\r\n|\r|\n)/g, "<br>") }}></span>
    </address>
  );
};

export const AddressEditorComponent = forwardRef(
  ({ address: addressFromProps, removeAddress }: { address: Address; removeAddress: () => void }, ref) => {
    const { t } = useTranslation();
    const [address, setAddress] = useState<Address>(clone(addressFromProps));
    const addressTypeValue = { label: "", value: "" };

    useImperativeHandle(ref, () => ({ getData: () => address }), [address]);

    switch (address.type) {
      case "billingAndShipping":
        addressTypeValue.label = addressTypeValue.value = t("Billing & Shipping");
        break;
      case "billing":
        addressTypeValue.label = addressTypeValue.value = t("Billing");
        break;
      case "shipping":
        addressTypeValue.label = addressTypeValue.value = t("Shipping");
        break;
      default:
    }

    const handleChange = (field: string, value: any) => {
      setAddress({ ...address, [field]: value });
    };

    return (
      <div>
        <div style={{ display: "grid", gap: "var(--gutter)" }}>
          <div style={{ display: "grid", gap: "var(--gutter)", gridTemplateColumns: "1fr 1fr" }}>
            <Select
              variant="overZone"
              label={t("Address type")}
              options={[
                { value: "billingAndShipping", label: t("Billing & Shipping") },
                { value: "billing", label: t("Billing") },
                { value: "shipping", label: t("Shipping") }
              ]}
              value={addressTypeValue}
              onChange={(option: any) => handleChange("type", option.value)}
            />
            <Input
              variant="overZone"
              label={t("Address description")}
              type="text"
              autoComplete="off"
              name="description"
              value={address.description || ""}
              onChange={(e: any) => handleChange(e.target.name, e.target.value)}
            />
          </div>
          <div style={{ display: "grid", gap: "var(--gutter)", gridTemplateColumns: "1fr 1fr" }}>
            <Input
              label={t("Address line 1")}
              variant="overZone"
              type="text"
              name="addressLine1"
              required
              value={address.addressLine1 || ""}
              onChange={(e: any) => handleChange(e.target.name, e.target.value)}
            />
            <Input
              label={t("Address line 2")}
              variant="overZone"
              type="text"
              name="addressLine2"
              value={address.addressLine2 || ""}
              onChange={(e: any) => handleChange(e.target.name, e.target.value)}
            />
          </div>
          <div style={{ display: "grid", gap: "var(--gutter)", gridTemplateColumns: "1fr 1fr 1fr 1fr" }}>
            <Input
              label={t("City")}
              variant="overZone"
              type="text"
              name="city"
              required
              value={address.city || ""}
              onChange={(e: any) => handleChange(e.target.name, e.target.value)}
            />
            <Input
              label={t("Post code / zip")}
              variant="overZone"
              type="text"
              name="postCode"
              value={address.postCode || ""}
              onChange={(e: any) => handleChange(e.target.name, e.target.value)}
            />
            <Input
              label={t("State")}
              type="text"
              variant="overZone"
              name="state"
              value={address.state || ""}
              onChange={(e: any) => handleChange(e.target.name, e.target.value)}
            />
            <Select
              label={t("Country")}
              variant="overZone"
              value={{ value: address.alpha2, label: getName(address.alpha2 || "") }}
              onChange={(option: any) => handleChange("alpha2", option.value)}
              options={getData().map((c: any) => ({ label: c.name, value: c.code }))}
            />
          </div>
          <div style={{ display: "flex", gap: "var(--gutter)", justifyContent: "space-between" }}>
            <span />
            <Button onClick={() => removeAddress()} variant="warning" type="button">
              {t("Remove")}
            </Button>
          </div>
        </div>
      </div>
    );
  }
);

export default AddressComponent;

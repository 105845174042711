import { useState, useRef } from "react";
import { Redirect, Prompt, Link } from "react-router-dom";
import Checkbox from "../styled/checkbox";
import Input from "../styled/input";
import Zone from "../styled/zone";
import Button from "../styled/button";
import { Button as ButtonV2 } from "../../componentsV2/Button";
import Loader from "../common/loader";
import { SelectCreatable } from "../styled/select";
import Listing, { ListingPropsToForward } from "../listings/single";
import FileUploader from "../fileUploader";
import { DescriptionEditor, IdentifiersEditor } from "./inputs";
import ImageDropzone from "../common/imageDropzone";
import Four0Four from "../global/404";
import { useMutation, useQuery } from "@apollo/client";
import { POST_ITEM_CREATE, POST_ITEM_IMAGE_REGISTER, POST_ITEM_IMAGE_URL, POST_ITEM_UPDATE } from "../../graphql/queries/item";
import isNumber from "is-number";
import { sanitizeListingData, removeTypenameAndIds, uploadToS3, swapElement } from "../../utils";
import * as Sentry from "@sentry/react";
import * as amplitude from "@amplitude/analytics-browser";
import { Config, Item } from "../../__generated__/graphql";
import { AddNotification } from "../../types/globals";
import { GET_CONFIG_METADATA_PRODUCTS } from "../../graphql/queries/config";
import { TFunction } from "i18next";
import { Typography } from "../../componentsV2/Typography";
import { ImageEntry } from "./edit.release";

interface MissingFields {
  title?: boolean;
  cat?: boolean;
  manufacturer?: boolean;
  weight?: boolean;
}

export default function NewProduct({
  item,
  config,
  configReload,
  isNew,
  setItem,
  t,
  addNotification
}: {
  item: Item;
  config: Config;
  configReload: any;
  isNew: boolean;
  setItem: any;
  t: TFunction;
  addNotification: AddNotification;
}) {
  const formId = "itemForm";
  const [redirect, setRedirect] = useState<string | null>(null);
  const [formData] = useState(new FormData());
  const [isUploadingImages, setIsUploadingImages] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const newListingRef = useRef<ListingPropsToForward>(null);
  const [missingFields, setMissingFields] = useState<MissingFields>({});
  const [createItem] = useMutation(POST_ITEM_CREATE);
  const [updateItem] = useMutation(POST_ITEM_UPDATE);
  const [getImageSignedUrl] = useMutation(POST_ITEM_IMAGE_URL);
  const [registerImage] = useMutation(POST_ITEM_IMAGE_REGISTER);
  const { data: metadata } = useQuery(GET_CONFIG_METADATA_PRODUCTS, { fetchPolicy: "no-cache" });

  const handleGenericInputUpdate = (name: string, value: any) => {
    setIsDirty(true);
    // @ts-ignore
    item.data[name] = value;
    setItem({ ...item });
    // @ts-ignore
    if (missingFields[name] && value) setMissingFields({ ...missingFields, [name]: false });
  };

  const handleShopDescriptionUpdate = (content: string) => {
    setIsDirty(true);
    item.descriptions.shop.text = content;
    setItem({ ...item });
  };

  const handleShortDescriptionUpdate = (content: string) => {
    setIsDirty(true);
    if (content.length > 160) return addNotification({ ok: 0, message: "Max number of characters is 160" });
    item.descriptions.shop.short = content;
    setItem({ ...item });
  };

  const handleIdentifierUpdate = (object: any) => {
    setIsDirty(true);
    item.data.identifiers = object;
    setItem({ ...item });
  };

  const handleSubmitItemData = async (e: any, noRedirect?: boolean) => {
    if (e) e.preventDefault();
    try {
      setIsSubmitting(true);
      isSubmittable();
      await updateItem({
        variables: {
          itemRef: item._id,
          descriptions: {
            short: item.descriptions.shop.short,
            long: item.descriptions.shop.text
          },
          itemUpdateProductInput: {
            title: item.data.title,
            manufacturer: item.data.manufacturer || "",
            type: item.data.type || "",
            images: removeTypenameAndIds(item.data.images || []),
            identifiers: removeTypenameAndIds(item.data.identifiers || []),
            cat: item.data.cat || "",
            weight: typeof item.data.weight === "string" ? parseInt(item.data.weight) : item.data.weight || 0,
            assetLink: item.data.assetLink || "",
            giftCard: {
              active: !!item.data.giftCard?.active,
              codeLength:
                typeof item.data.giftCard?.codeLength === "string"
                  ? parseInt(item.data.giftCard?.codeLength)
                  : item.data.giftCard?.codeLength || 0,
              prefix: item.data.giftCard?.prefix,
              singleSpending: !!item.data.giftCard?.singleSpending
            }
          }
        }
      });
      setIsDirty(false);
      if (!noRedirect) {
        addNotification({ ok: 1, message: "Item updated" });
        setRedirect(item.path);
      }
    } catch (e: any) {
      Sentry.captureException(e);
      addNotification({ ok: 1, message: e.message });
    } finally {
      setIsSubmitting(false);
    }
  };

  const readUploadedFileAsText = (inputFile: File) => {
    const temporaryFileReader = new FileReader();
    return new Promise((resolve, reject) => {
      temporaryFileReader.onerror = () => {
        temporaryFileReader.abort();
        reject(new DOMException("Problem parsing input file."));
      };
      temporaryFileReader.onload = () => {
        resolve(temporaryFileReader.result);
      };
      temporaryFileReader.readAsDataURL(inputFile);
    });
  };

  const handleAlternativeImageUpload = async (file: File, index: number) => {
    if (!file) return;
    setItem({ ...item });

    try {
      const fileToSend = file;
      const { data } = await getImageSignedUrl({ variables: { itemRef: item._id as string, filename: fileToSend.name } });
      if (!data?.itemImageSignedUrl) return;
      const options = { headers: { "Content-Type": fileToSend.type, "Cache-Control": "max-age=31536000" } };
      await uploadToS3(data.itemImageSignedUrl.signedUrl, fileToSend, options);
      const registerData = {
        key: data.itemImageSignedUrl.key,
        filename: fileToSend.name,
        index,
        itemRef: item._id as string,
        alternative: true
      };
      const { data: imageRegisterResults } = await registerImage({ variables: registerData });
      if (imageRegisterResults?.itemImageRegister) {
        setItem({ ...imageRegisterResults.itemImageRegister });
        addNotification({ ok: 1, message: t("Image added") });
      }
    } catch (error: any) {
      addNotification({ ok: 0, message: error.toString() });
    }
  };

  const handleImageDrop = async (_: any, files: File[]) => {
    setIsDirty(true);
    if (isNew) {
      await files.forEach(async f => {
        try {
          const fileContents = await readUploadedFileAsText(f);
          item.data.images.push({ uri: fileContents as string });
          setItem({ ...item });
        } catch (e: any) {
          addNotification({ ok: 0, message: e.toString() });
        }
      });
    } else {
      if (isDirty) await handleSubmitItemData(null, true);
      setIsUploadingImages(true);
      try {
        for (const fileToSend of formData.getAll("files")) {
          const { data } = await getImageSignedUrl({ variables: { itemRef: item._id, filename: (fileToSend as File).name } });
          if (!data?.itemImageSignedUrl) continue;
          const options = { headers: { "Content-Type": (fileToSend as File).type, "Cache-Control": "max-age=31536000" } };
          await uploadToS3(data.itemImageSignedUrl.signedUrl, fileToSend as File, options);
          const registerData = {
            key: data.itemImageSignedUrl.key,
            filename: (fileToSend as File).name,
            itemRef: item._id,
            alternative: false
          };
          const { data: registeredData } = await registerImage({ variables: registerData });
          if (registeredData?.itemImageRegister) {
            setItem({ ...registeredData?.itemImageRegister });
            addNotification({ ok: 1, message: t("Image added") });
          }
        }
        formData.delete("files");
      } catch (e: any) {
        addNotification({ ok: 0, message: e.data.toString() });
      } finally {
        setIsUploadingImages(false);
      }
    }
  };

  const handleAddItem = async (e: any) => {
    e.preventDefault();
    try {
      setIsSubmitting(true);
      isSubmittable();
      if (!newListingRef.current) return addNotification({ ok: 0, message: "Listing error" });
      const listingToAdd = newListingRef.current.getListing();

      const itemDataToSend = { ...item.data, weight: typeof item.data.weight === "string" ? parseInt(item.data.weight) : item.data.weight };
      if (itemDataToSend.giftCard?.codeLength)
        itemDataToSend.giftCard.codeLength =
          typeof itemDataToSend.giftCard.codeLength === "string"
            ? parseInt(itemDataToSend.giftCard.codeLength)
            : itemDataToSend.giftCard.codeLength;
      const descriptions = { short: item.descriptions.shop.short, long: item.descriptions.shop.text };

      const images = formData.getAll("files");

      sanitizeListingData(listingToAdd);
      itemDataToSend.images = [];

      if (
        (!isNumber(listingToAdd.prices?.sale) && !isNumber(listingToAdd.prices?.beforeTaxes)) ||
        !isNumber(listingToAdd.stock?.quantity)
      ) {
        addNotification({ ok: 0, message: "Price and stock values are required" });
        setIsSubmitting(false);
        return;
      }
      amplitude.track("Item created", { type: "Product" });
      const { data } = await createItem({
        variables: { type: "ProductItem", descriptions, listings: [listingToAdd], itemCreateProductInput: itemDataToSend }
      });
      const addedItem = data?.itemCreate;
      if (!addedItem) throw new Error("Item create error");
      if (images.length) {
        try {
          for (const fileToSend of formData.getAll("files") as any) {
            const { data } = await getImageSignedUrl({ variables: { itemRef: addedItem._id, filename: fileToSend.name } });
            if (!data?.itemImageSignedUrl) continue;
            const options = { headers: { "Content-Type": fileToSend.type, "Cache-Control": "max-age=31536000" } };
            await uploadToS3(data?.itemImageSignedUrl.signedUrl, fileToSend, options);
            const registerData = {
              key: data?.itemImageSignedUrl.key,
              filename: fileToSend.name,
              itemRef: addedItem._id,
              alternative: false
            };
            await registerImage({ variables: registerData });
          }
          formData.delete("files");
        } catch (e: any) {
          addNotification({ ok: 0, message: e.message });
        } finally {
          setIsUploadingImages(false);
        }
      }
      configReload();
      setRedirect(data.itemCreate.path);
      addNotification({ ok: 1, message: "Item created" });
    } catch (e: any) {
      addNotification({ ok: 0, message: e.message });
    } finally {
      setIsSubmitting(false);
    }
  };

  const isSubmittable = () => {
    if (isSubmitting) return false;

    if (!item.data.title) throw new Error(t("A title is required"));
    if (!isNumber(item.data.weight)) throw new Error(t("A weight is required"));
    if (!item.data.manufacturer) throw new Error(t("A manufacturer is required"));
  };

  const handleManufacturerChange = (value: string) => {
    setIsDirty(true);
    item.data.manufacturer = value;
    setItem({ ...item });
    if (missingFields.manufacturer && item.data.manufacturer.length) setMissingFields({ ...missingFields, manufacturer: false });
  };

  const handleTypeChange = (value: string) => {
    setIsDirty(true);
    item.data.type = value;
    setItem({ ...item });
  };

  const handleGiftCardStatus = (e: any) => {
    setIsDirty(true);
    const status = e.target.checked;
    if (status)
      item.data.giftCard = {
        active: true,
        codeLength: 6,
        prefix: "",
        singleSpending: false
      };
    else item.data.giftCard = null;
    setItem({ ...item });
  };

  const handleGiftCardChange = (e: any) => {
    setIsDirty(true);
    if (e.target.type !== "checkbox")
      item.data.giftCard = {
        ...item.data.giftCard,
        [e.target.name]: e.target.value.toUpperCase()
      };
    else
      item.data.giftCard = {
        ...item.data.giftCard,
        [e.target.name]: e.target.checked
      };
    setItem({ ...item });
  };

  const handleDeleteImage = async (index: number) => {
    setIsDirty(true);
    item.data.images.splice(index, 1);
    if (!isNew) {
      try {
        setItem({ ...item });
      } catch (e: any) {
        addNotification({ message: e.message, ok: 0 });
      }
    } else {
      const files = formData.getAll("files");
      files.splice(index, 1);
      formData.delete("files");
      files.forEach(item => {
        formData.append("files", item);
      });
      setItem({ ...item });
    }
  };

  const handleDeleteAlternativeImage = async (index: number) => {
    setIsDirty(true);
    delete item.data.images[index].alternative;
    setItem({ ...item });
  };

  const handleReorder = async (index: number, direction: string) => {
    setIsDirty(true);
    const copiedImages = item.data.images;
    if (direction === "up" && index > 0) {
      swapElement(copiedImages, index, index - 1);
    } else if (direction === "down" && index < copiedImages.length - 1) {
      swapElement(copiedImages, index, index + 1);
    }
    setItem({ ...item });
  };

  if (redirect) return <Redirect to={redirect} />;
  if (!isNew && item === null) return <Four0Four />;
  if (!isNew && item === undefined) return <Loader />;
  if (!item) return null;

  const shopDescription = item.descriptions.shop.text;

  return (
    <div id="itemEdit" className="product">
      <Prompt when={isDirty} message={() => "Your changes will be lost, are you sure you want to leave this page ?"} />
      <section className="header">
        <div className="left">
          <Typography variant="pageTitle" tag="h1">
            {isNew ? t("Add a product") : t("Edit Product")}
          </Typography>
          {!isNew && item.path ? (
            <Link to={item.path}>
              <Button type="button" variant="noStyle">
                {t("Back")}
              </Button>
            </Link>
          ) : null}
        </div>
        <div className="right">
          <ButtonV2 variant="primary" type="submit" form={formId} disabled={isSubmitting}>
            {isSubmitting ? <Loader /> : isNew ? t("Submit") : t("Save")}
          </ButtonV2>
        </div>
      </section>
      <div id="content">
        <form onSubmit={isNew ? handleAddItem : handleSubmitItemData} id={formId}>
          <section id="images">
            <h3>{t("Images")}</h3>
            <ImageDropzone formData={formData} uploadingState={isUploadingImages} onDrop={handleImageDrop} t={t} item={item} />
            {item.data.images.length ? (
              <div className="entries">
                {item.data.images.map((i, index) => (
                  <div key={`${i.uri}-${index}`} className="image">
                    <ImageEntry index={index} item={item} image={i} handleMove={direction => handleReorder(index, direction)} />
                    <div className="imageOptions">
                      <Button variant="danger" type="button" onClick={() => handleDeleteImage(index)}>
                        {t("Delete")}
                      </Button>
                      {i.alternative ? (
                        <div className="alternative">
                          <Button type="button" variant="danger" onClick={() => handleDeleteAlternativeImage(index)}>
                            {t("Delete hover image")}
                          </Button>
                        </div>
                      ) : !isNew ? (
                        <FileUploader
                          // isUploading={!!i.alternativeUploading}
                          accept="image/png, image/jpeg"
                          label="Add hover image"
                          name="alternative"
                          handleFile={(file: File) => handleAlternativeImageUpload(file, index)}
                        />
                      ) : null}
                    </div>
                  </div>
                ))}
              </div>
            ) : null}
          </section>
          <section id="information">
            <p style={{ fontStyle: "italic" }}>
              <strong>{t("Important note:")}</strong>
              {t(
                "When adding product variants for sizes or colors, please submit additional listings with different options to existing products instead of duplicating product entries"
              )}
            </p>
            <hr />
            <h3>{t("Item information")}</h3>
            <div id="fields">
              <Input
                label={t("Title") + "*"}
                name="title"
                required
                form="information"
                value={item.data.title}
                placeholder={t("Title")}
                className={missingFields.title ? "required" : ""}
                onChange={(e: any) => handleGenericInputUpdate(e.target.name, e.target.value)}
              />
              <Input
                label={t("Product catalogue number") + "*"}
                name="cat"
                required
                value={item.data.cat}
                placeholder={t("Product catalogue number")}
                className={missingFields.cat ? "required" : ""}
                onChange={(e: any) => handleGenericInputUpdate(e.target.name, e.target.value)}
              />
              <SelectCreatable
                label={t("Manufacturer / Vendor") + "*"}
                name="manufacturer"
                isClearable={false}
                onCreateOption={handleManufacturerChange}
                value={item.data.manufacturer ? { value: item.data.manufacturer, label: item.data.manufacturer } : null}
                placeholder={t("Select or start typing to create an entry") + "..."}
                onChange={(option: any) => handleManufacturerChange(option.value)}
                className={missingFields.manufacturer ? "required" : ""}
                options={(metadata?.configMetadata?.manufacturers || []).map(c => ({ label: c, value: c }))}
              />
              <SelectCreatable
                label={t("Product type")}
                name="type"
                isClearable={false}
                onCreateOption={handleTypeChange}
                placeholder={t("Select or start typing to create an entry") + "..."}
                value={item.data.type ? { value: item.data.type, label: item.data.type } : null}
                onChange={(option: any) => handleTypeChange(option.value)}
                options={[{ label: "-", value: "" }].concat((metadata?.configMetadata?.types || []).map(c => ({ label: c, value: c })))}
              />
              <Input
                label={t("Weight in grams") + "*"}
                name="weight"
                type="number"
                required
                onWheel={(e: any) => e.target.blur()}
                className={missingFields.weight ? "required" : ""}
                value={item.data.weight}
                placeholder={t("Weight in grams")}
                onChange={(e: any) => handleGenericInputUpdate(e.target.name, e.target.value)}
              />
              <Input
                label={t("Public asset download link")}
                type="url"
                name="assetLink"
                value={item.data.assetLink || ""}
                placeholder={t("An URL link to download extra assets") + "..."}
                onChange={(e: any) => handleGenericInputUpdate(e.target.name, e.target.value)}
              />
              <DescriptionEditor label={t("Full description")} content={shopDescription || ""} onChange={handleShopDescriptionUpdate} />
              <DescriptionEditor
                label={t("Tag line / SEO description - 160 characters max")}
                content={item.descriptions.shop.short || ""}
                onChange={handleShortDescriptionUpdate}
              />
              <IdentifiersEditor item={item} handleIdentifierUpdate={handleIdentifierUpdate} t={t} />
              <div className="giftCard zone">
                <Checkbox
                  label={t("This is a gift card product")}
                  name="giftCard"
                  onChange={handleGiftCardStatus}
                  checked={item.data.giftCard?.active}
                />
                {item.data.giftCard?.active ? (
                  <Zone className="giftCardEditor" style={{ marginTop: 10 }}>
                    <div className="inputs">
                      <p>
                        {t(
                          "When a gift card product is sold from the Eshop or Point Of Sale, a corresponding gift card voucher code is created."
                        )}{" "}
                        <br />
                        <br />{" "}
                        {t(
                          "A confirmation email will be sent to the buyer (if any) including the voucher code that can then be applied at checkout."
                        )}
                      </p>
                      <Input
                        variant="overZone"
                        label={t("Voucher code prefix (optional)")}
                        type="text"
                        name="prefix"
                        pattern="[a-zA-Z0-9-_]+"
                        maxLength="8"
                        onChange={handleGiftCardChange}
                        placeholder={t("Voucher code prefix (optional)")}
                        value={item.data.giftCard.prefix || ""}
                      />
                      <Input
                        variant="overZone"
                        label={t("Code length (default 6 characters)")}
                        onWheel={(e: any) => e.target.blur()}
                        type="number"
                        min="4"
                        max="10"
                        name="codeLength"
                        onChange={handleGiftCardChange}
                        value={item.data.giftCard.codeLength || ""}
                      />
                      <Checkbox
                        label={t("Single spending / no balance: the gift card may only be used once and will be marked as redeemed.")}
                        name="singleSpending"
                        onChange={handleGiftCardChange}
                        checked={item.data.giftCard.singleSpending}
                      />
                    </div>
                  </Zone>
                ) : null}
              </div>
            </div>
          </section>
        </form>
      </div>
      {isNew ? (
        <Zone id="listings">
          <div id="listingsHeader" />
          <Listing
            config={config}
            overZone={true}
            listing={item.listings[0]}
            ref={newListingRef}
            item={item}
            edit={isNew || false}
            hideSubmit={true}
            new={isNew}
          />
        </Zone>
      ) : null}
    </div>
  );
}

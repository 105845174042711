import { useEffect } from "react";
import Loader from "../common/loader";
import Liner from "../common/liner";
import moment from "moment";
import { useQuery } from "@apollo/client";
import { GET_JOBS } from "../../graphql/queries/job";
import { Typography } from "../../componentsV2/Typography";
import { useTranslation } from "react-i18next";
import { MultiSelect } from "../../componentsV2/MultiSelect";
import { useHistory, useLocation } from "react-router-dom";
import URI from "urijs";
import { MultiSelectValueType } from "../../componentsV2/MultiSelect/MultiSelect.types";
import Pagination from "../common/pagination";
import { Button } from "../../componentsV2/Button";
import { JOB_STATUS } from "../../config/jobs";

export const mapTagsToMultiSelectType = (tags?: string[]): MultiSelectValueType[] => {
  return tags?.map(tag => ({ label: tag, value: tag })) ?? [];
};

export const useHandleFilterChange = (filterName: string) => {
  const location = useLocation();
  const history = useHistory();
  const currentUri = new URI(location.pathname + location.search);

  const handleFilterChange = async (values: MultiSelectValueType[]) => {
    if (values.length > 0) {
      currentUri.setSearch({ [filterName]: values.map(selectedValue => selectedValue.value) }).removeSearch(["page", "term"]);
    } else currentUri.removeSearch(filterName);
    history.push(currentUri.resource());
  };

  return { handleFilterChange };
};

export const useSelectedTasks = () => {
  const location = useLocation();
  const currentUri = new URI(location.pathname + location.search);
  const searchQuery = currentUri.search(true);

  const tasks = searchQuery?.tasks;

  let selectedTags: string[] = [];
  if (tasks) {
    selectedTags = Array.isArray(tasks) ? tasks : [tasks];
  }

  return selectedTags;
};

export default function Jobs() {
  const { t } = useTranslation();
  const location = useLocation();
  const currentUri = new URI(location.pathname + location.search);
  const searchQuery = currentUri.search(true);

  const selectedTasks = useSelectedTasks();
  const { handleFilterChange } = useHandleFilterChange("tasks");

  const { data, loading, refetch } = useQuery(GET_JOBS, {
    variables: { page: parseInt(searchQuery.page || 1), tasks: selectedTasks },
    fetchPolicy: "cache-and-network"
  });
  const jobs = data?.jobs?.jobs;
  const hasProcessingJobs = data?.jobs?.hasProcessingJobs;
  const pagination = data?.jobs?.pagination;
  const tasksOptions = data?.jobs?.tasks;

  const selectedOptions = tasksOptions?.filter(o => selectedTasks.indexOf(o.value) !== -1) || [];
  document.title = t("Jobs");

  useEffect(() => {
    if (hasProcessingJobs) {
      const id = setInterval(() => {
        refetch();
      }, 3000);
      return () => clearInterval(id);
    }
  }, [hasProcessingJobs]);

  if (!jobs || !tasksOptions) return <Loader />;

  const getLevelFromStatus = (status: string) => {
    if (status === "pending") return "info";
    else if (status === "processing") return "info";
    else if (status === "failed") return "warning";
    else if (status === "complete") return "success";
  };

  return (
    <div id="jobs">
      <section className="header">
        <Typography variant="pageTitle" tag="h1">
          {t("Jobs")}
        </Typography>
        {loading ? <Loader /> : null}
      </section>
      <section style={{ display: "flex" }}>
        <MultiSelect
          size="large"
          title={t("Job types")}
          options={tasksOptions}
          selectedOptions={selectedOptions}
          handleChange={handleFilterChange}
        />
      </section>
      <>
        {pagination?.pages ? <Pagination pagination={pagination} currentUri={currentUri} /> : null}
        {jobs ? (
          <section id="jobsTable">
            {jobs.map((j, index) => (
              <Liner index={index} className="jobsTableEntry" key={j._id}>
                <div className={"status"}>
                  <div className={j.status}>
                    <Typography variant="copy" level={getLevelFromStatus(j.status)} style={{ textTransform: "capitalize" }} tag="p">
                      {j.status}
                    </Typography>
                  </div>
                  {j.user ? (
                    <Typography variant="copy" level={"secondary"} tag="p">
                      {t("Requested by")} {j.user.name}
                    </Typography>
                  ) : (
                    ""
                  )}
                  <Typography variant="copy" level={"secondary"} tag="p">
                    {moment(j.created).fromNow()}
                  </Typography>
                  {typeof j.completion === "number" && j.status === JOB_STATUS.PROCESSING ? (
                    <Typography variant="copy" level="info" tag="p">
                      {j.completion * 100}%
                    </Typography>
                  ) : null}
                </div>
                <div className="type">
                  <Typography variant="copy" style={{ marginBottom: "var(--gutter)" }} level="normal" tag="p">
                    {j.task?.name}
                  </Typography>
                  {j.results?.message ? (
                    <Typography variant="copy" level="normal" tag="p">
                      {j.results.message}
                    </Typography>
                  ) : null}
                  <Typography variant="copy" level="secondary" style={{ marginBottom: "var(--gutter)" }} tag="p">
                    {j.options}
                  </Typography>
                  {j.results?.logs?.map((l, idx) => (
                    <Typography key={idx} variant="copy" tag="p" level="secondary">
                      {l}
                    </Typography>
                  ))}
                </div>
                <div className="download">
                  {j.results?.uri && (
                    <a href={j.results.uri} download={j.results.uri}>
                      <Button variant="primary">{t("Download")}</Button>
                    </a>
                  )}
                  {j.results?.fileSizeString ? (
                    <Typography style={{ marginTop: "var(--gutter)" }} variant="copy" level="secondary" tag="p">
                      {j.results?.fileSizeString}
                    </Typography>
                  ) : null}
                </div>
              </Liner>
            ))}
          </section>
        ) : (
          <Loader />
        )}
      </>
    </div>
  );
}

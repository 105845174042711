import { useState } from "react";
import { Button as ButtonV2 } from "../../../../componentsV2/Button";
import Input from "../../../styled/input";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import Loader from "../../../common/loader";
import { useQuery } from "@apollo/client";
import { GET_STRIPE_INTENT } from "../../../../graphql/queries/config";
import { StripeCardElement } from "@stripe/stripe-js";
import { AddNotification } from "../../../../types/globals";
import { useTranslation } from "react-i18next";

const CardForm = ({ addNotification, onFinish }: { addNotification: AddNotification; onFinish: any }) => {
  const [loading, setLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const { data } = useQuery(GET_STRIPE_INTENT);
  const { t } = useTranslation();
  const intentSecret = data?.configStripeIntent;

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setLoading(true);
    try {
      if (!stripe || !elements) return;
      if (!intentSecret) return addNotification({ ok: 0, message: "Invalid payment intent" });

      const accountholderName = event.target.name;
      const email = event.target.email;

      const result = await stripe.confirmCardSetup(intentSecret, {
        payment_method: {
          card: elements.getElement(CardElement) as StripeCardElement,
          billing_details: {
            name: accountholderName.value,
            email: email.value
          }
        }
      });

      if (result.error) return addNotification({ ok: 0, message: result.error.message || "" });

      onFinish(result.setupIntent);
    } catch (e: any) {
      addNotification({ ok: 0, message: e.data });
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="cardForm">
      <div style={{ display: "flex", justifyContent: "flex-start", gap: "var(--gutter)" }}>
        <div className="left">
          <Input variant="overZone" label="Name" name="name" placeholder="Jenny Rosen" required />
        </div>
        <div className="right">
          <Input variant="overZone" name="email" label="Email Address" type="email" placeholder="jenny@example.com" required />
        </div>
      </div>
      <div className="cardInput">
        <CardElement />
      </div>
      <ButtonV2 variant="primary" type="submit" disabled={!stripe || loading}>
        {loading ? <Loader /> : t("Submit")}
      </ButtonV2>
    </form>
  );
};

export default CardForm;

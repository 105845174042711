import URI from "urijs";
import http from "../httpService";

export function getCountryData() {
  const uri = new URI(process.env.VITE_API_ENDPOINT + "/countries");
  return http.get(uri.toString());
}

export function getGoogleOauthUrl(data?: any) {
  return http.get(process.env.VITE_API_ENDPOINT + "/config/google/oauth", data);
}

export function submitGoogleOauthCode(data: any) {
  return http.post(process.env.VITE_API_ENDPOINT + "/config/google/oauth", data);
}

export function submitGoogleAnalyticsView(data: any) {
  return http.put(process.env.VITE_API_ENDPOINT + "/config/google/view", data);
}

export function disconnectGoogleAnalytics(data?: any) {
  return http.delete(process.env.VITE_API_ENDPOINT + "/config/google/oauth", data);
}

import { gql } from "../../__generated__/gql";

export const GET_DISCOGS_INVENTORY = gql(`
  query discogsInventory($page: Int!) {
    discogsInventory(page: $page) {
      listings {
        id
        release {
          id
          thumbnail
          description
        }
        original_price {
          value
          formatted
          curr_abbr
        }
        location
        condition
        sleeve_condition
        posted
        uri
        comments
        privateComments
        price {
          value
          currency
        }
        path
        exists
        discrepancy
      }
      pagination {
        pages
        page
        count
      }
      stats {
        foundItems
      }
    }
  }
`);

export const GET_DISCOGS_INVENTORY_IMPORT = gql(`
  query discogsInventoryImport {
    discogsInventoryImport {
      results {
        message
      }
    }
  }
`);

export const GET_DISCOGS_SEARCH = gql(`
  query discogsSearch($term: String!, $type: String) {
    discogsSearch(term: $term, type: $type) {
      results {
        style
        thumb
        title
        type
        formats {
          qty
          descriptions
          name
          text
        }
        format
        uri
        label
        country
        catno
        year
        genre
        id
      }
      pagination {
        pages
      }
    }
  }
`);

export const GET_DISCOGS_ORDERS = gql(`
  query discogsOrders($page: Int!) {
    discogsOrders(page: $page) {
      orders {
        id
        uri
        status
        created
        uri
        items {
          id
          release {
            id
            description
          }
          price {
            value
            currency
          }
          state {
            path
            available
          }
          media_condition
          sleeve_condition
        }
        shipping_address
        additional_instructions
        archived
        last_activity
        buyer {
          username
        }
        total {
          value
          currency
        }
        imported
      }
      pagination {
        pages
        page
        count
      }
    }
  }
`);

import { gql } from "../../__generated__/gql";

export const JOB_FIELDS = gql(`
  fragment JobFields on Job {
    _id
    type
    created
    user {
      name
    }
    task {
      name
      path
    }
    options
    status
    hidden
    completion
    results {
      uri
      key
      message
      fileSizeString
      fileSize
      logs
    }
  }
`);

export const GET_JOBS = gql(`
  query jobs($taskName: String, $tasks: [String!], $statuses: [String!], $page: Int) {
    jobs(page: $page, taskName: $taskName, tasks: $tasks, statuses: $statuses) {
      jobs {
        ...JobFields
      }
      hasProcessingJobs
      pagination {
        count
        pages
        page
      }
      tasks {
        label
        value
      }
    }
  }
`);

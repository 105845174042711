import { useState } from "react";
import { useStripe, useElements, IbanElement } from "@stripe/react-stripe-js";
import { Button as ButtonV2 } from "../../../../componentsV2/Button";
import Input from "../../../styled/input";
import Loader from "../../../common/loader";
import { useQuery } from "@apollo/client";
import { GET_STRIPE_INTENT } from "../../../../graphql/queries/config";
import { StripeIbanElement } from "@stripe/stripe-js";
import { AddNotification } from "../../../../types/globals";
import { useTranslation } from "react-i18next";
import { Config } from "../../../../__generated__/graphql";
import { Typography } from "../../../../componentsV2/Typography";

const IBAN_STYLE = {
  base: {
    color: "#32325d",
    fontSize: "16px",
    "::placeholder": {
      color: "grey"
    },
    ":-webkit-autofill": {
      color: "#32325d"
    }
  },
  invalid: {
    color: "#fa755a",
    iconColor: "#fa755a",
    ":-webkit-autofill": {
      color: "#fa755a"
    }
  }
};

const IbanForm = ({ addNotification, config, onFinish }: { addNotification: AddNotification; config: Config; onFinish: any }) => {
  const { data } = useQuery(GET_STRIPE_INTENT);
  const [loading, setLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const { t } = useTranslation();
  const IBAN_ELEMENT_OPTIONS = { supportedCountries: ["SEPA"], placeholderCountry: config.taxes.country.code, style: IBAN_STYLE };

  const intentSecret = data?.configStripeIntent;

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setLoading(true);
    try {
      if (!stripe || !elements) return;
      if (!intentSecret) return addNotification({ ok: 0, message: "Invalid payment intent" });

      const iban = elements.getElement(IbanElement);

      const accountholderName = event.target.name;
      const email = event.target.email;

      setLoading(true);
      const result = await stripe.confirmSepaDebitSetup(intentSecret, {
        payment_method: {
          sepa_debit: iban as StripeIbanElement,
          billing_details: {
            name: accountholderName.value,
            email: email.value,
            address: {
              line1: event.target.addressLine1.value,
              city: event.target.city.value,
              country: config.taxes.country.code
            }
          }
        }
      });
      if (result.error) return addNotification({ ok: 0, message: result.error.message || "" });
      onFinish(result.setupIntent);
    } catch (e: any) {
      addNotification({ ok: 0, message: e.message || e.toString() });
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="ibanForm">
      <div style={{ display: "flex", justifyContent: "flex-start", gap: "var(--gutter)" }}>
        <div className="left">
          <Input variant="overZone" label="Name" name="name" placeholder="Jenny Rosen" required />
        </div>
        <div className="right">
          <Input variant="overZone" name="email" label="Email Address" type="email" placeholder="jenny.rosen@example.com" required />
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "flex-start", gap: "var(--gutter)" }}>
        <div className="right">
          <Input variant="overZone" name="addressLine1" label="Address line1" type="text" placeholder="123 Well Street" required />
        </div>
        <div className="right">
          <Input variant="overZone" name="city" label="City" type="text" placeholder="New York" required />
        </div>
      </div>

      <div className="ibanInput">
        <label>
          {t("IBAN Number")}
          <IbanElement options={IBAN_ELEMENT_OPTIONS} />
        </label>
      </div>

      <div style={{ display: "flex" }}>
        <span />
        <ButtonV2 variant="primary" type="submit" disabled={!stripe || loading || !intentSecret}>
          {loading ? <Loader /> : t("Submit")}
        </ButtonV2>
      </div>

      <Typography variant="copy" tag="p" level="secondary">
        {t(
          "By providing your payment information and confirming this payment, you authorise (A) Common Ground and Stripe, our payment service provider, to send instructions to your bank to debit your account and (B) your bank to debit your account in accordance with those instructions"
        )}
        . <br />
        {t(
          "As part of your rights, you are entitled to a refund from your bank under the terms and conditions of your agreement with your bank. A refund must be claimed within 8 weeks starting from the date on which your account was debited. Your rights are explained in a statement that you can obtain from your bank. You agree to receive notifications for future debits up to 2 days before they occur"
        )}
        .
      </Typography>
    </form>
  );
};

export default IbanForm;

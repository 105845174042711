import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import Button from "../styled/button";
import { Button as ButtonV2 } from "../../componentsV2/Button";

import Zone from "../styled/zone";
import Loader from "../common/loader";
import InputWithSubmit from "../styled/inputWithSubmit";
import { discogsSearch } from "../../@services/discogsService";
import Tooltip from "../common/tooltip";
import { GlobalStore } from "../../stores/global";
import Modal from "../modal";
import clone from "clone";
import { useMutation, useLazyQuery } from "@apollo/client";
import { GET_ITEM_STATS_FOR_IDS, GET_ITEM_BOOK_SEARCH, POST_ITEM_CREATE, GET_ITEM_FROM_DATABASE } from "../../graphql/queries/item";
import * as amplitude from "@amplitude/analytics-browser";
import styled from "styled-components";
import moment from "moment";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { isNearInventoryLimit, removeTypename } from "../../utils";
import { Config } from "../../__generated__/graphql";
import { EThemes } from "../../types/globals";
import { applyOpacity, colorsAsRgbArray, colorsAsRgbString } from "@common-ground-io/colors";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import { ModalHeaderContainer } from "../../componentsV2/SectionHeader/SectionHeader.styles";
import { Typography } from "../../componentsV2/Typography";
import { DiscogsRelease } from "../../types/Discogs/release";
import { GET_DISCOGS_SEARCH } from "../../graphql/queries/discogs";
import { ProgressBar } from "../preferences/tabs/billing";

interface Block {
  label: string | null | unknown;
  id: string;
  path?: string | null;
}

const blocksWithLabelTranslation = (): Block[] => {
  const { t } = useTranslation();
  return [
    { label: null, id: "releaseDiscogs" },
    {
      label: t("Add a release manually"),
      id: "releaseManually",
      path: "/item/new?type=ReleaseItem"
    },
    {
      label: t("Add a product"),
      id: "product",
      path: "/item/new?type=ProductItem"
    },
    { label: t("Add a book"), id: "book", path: null }
  ];
};

const getBackgroundColor = ({ theme, inverted }: { theme: { name: string }; inverted: boolean }) => {
  if (!theme) return colorsAsRgbString.greyLighter;

  if (theme.name === "light") {
    if (inverted) return colorsAsRgbString.greyLightest;
    else return colorsAsRgbString.greyLighter;
  } else if (theme.name === "dark") {
    if (inverted) return colorsAsRgbString.greyDarkest;
    else return colorsAsRgbString.greyDarker;
  }
};

const BlockButton = styled.button<{ isMobile: boolean }>`
  border-radius: 10px;
  padding: 15px;
  height: ${props => (props.isMobile ? "100px" : "150px")};
  outline: none;
  border: 1px solid transparent;
  :hover {
    border-color: ${props => (props.theme.name === "light" ? colorsAsRgbString.greyLighter : colorsAsRgbString.grey)};
  }
  background-color: ${props => getBackgroundColor(props as any)};
`;

const AddReleaseLink = ({ isBelowIpad, theme }: { isBelowIpad: boolean; theme: EThemes }) => {
  const modalRef = useRef<any>(null);
  const location = useLocation();

  useEffect(() => {
    modalRef.current?.close();
  }, [location.pathname + location.search]);

  const handleClick = () => {
    const modalIsOpen = modalRef?.current?.modalIsOpen;
    if (modalIsOpen) modalRef.current.close();
    else modalRef.current.open();
    amplitude.track("Open Add Release modal");
  };

  return (
    <>
      <Modal
        ref={modalRef}
        style={{
          width: isBelowIpad ? "95vw" : "60vw",
          minHeight: "40%"
        }}>
        <div id="releaseAddModal">
          <div className="content">
            <ReleaseAdd close={() => modalRef.current?.close()} isOpen={modalRef.current?.isOpen} />
          </div>
          <div className="footer"></div>
        </div>
      </Modal>
      <button type="button" className="addItemButton" onClick={handleClick}>
        <Canvas theme={theme} width={35} height={35} />
      </button>
    </>
  );
};

export const Canvas = ({ theme, width, height }: { theme: EThemes; width: number; height: number }) => {
  const canvasRef = useRef<any>(null);

  const modes: any = {
    dark: {
      inactive: {
        backgroundColor: colorsAsRgbString.primary,
        color: colorsAsRgbString.white
      },
      active: {
        backgroundColor: "#9786FF", // Todo: change to purple light from library
        color: colorsAsRgbString.white
      }
    },
    light: {
      inactive: {
        backgroundColor: colorsAsRgbString.primary,
        color: colorsAsRgbString.white
      },
      active: {
        backgroundColor: applyOpacity(colorsAsRgbArray.primary, 0.6),
        color: colorsAsRgbString.white
      }
    }
  };

  const [state, setState] = useState<string>("inactive");
  const offset = 9;
  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");

    context.fillStyle = modes[theme][state].backgroundColor;
    context.fillRect(0, 0, context.canvas.width, 35);

    context.moveTo(offset, width / 2);
    context.lineWidth = 1;
    context.strokeStyle = modes[theme][state].color;

    context.lineTo(width - offset, width / 2);
    context.stroke();

    context.moveTo(width / 2, offset);
    context.lineTo(width / 2, width - offset);
    context.stroke();

    context.stroke();
  }, [state, theme]);

  return (
    <canvas
      width={width}
      height={height}
      onMouseEnter={() => setState("active")}
      onMouseLeave={() => setState("inactive")}
      ref={canvasRef}
    />
  );
};

const AddReleasePage = () => {
  const modalRef = useRef<any>(null);
  const location = useLocation();
  document.title = "Add release";

  useEffect(() => {
    modalRef.current?.close();
  }, [location.pathname + location.search]);

  return <ReleaseAdd close={() => modalRef.current?.close()} />;
};

export const ReleaseAdd = ({ close }: { close: any; isOpen?: boolean }) => {
  const { config, addNotification, isMobile } = GlobalStore.useState(c => c) as { config: Config; addNotification: any; isMobile: boolean };
  const [suggestions, setSuggestions] = useState<any>();
  const [discogsId, setDiscogsId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [step, setStep] = useState("release");
  const [term, setTerm] = useState<any>();
  const [error, setError] = useState("");
  const history = useHistory();
  const [getItemsStats] = useLazyQuery(GET_ITEM_STATS_FOR_IDS, { fetchPolicy: "cache-and-network" });
  const [getItemFromDatabase] = useLazyQuery(GET_ITEM_FROM_DATABASE, { fetchPolicy: "cache-and-network" });
  const [createItem] = useMutation(POST_ITEM_CREATE);
  const [backendDiscogsSearch] = useLazyQuery(GET_DISCOGS_SEARCH, { fetchPolicy: "cache-and-network" });
  const { t } = useTranslation();

  const token = config.discogs.token;
  const accessData = config.discogs.accessData;

  const handleLoadRelease = async (id: number) => {
    if (isLoading) return;
    try {
      setIsLoading(true);
      amplitude.track("Item created", { type: "Release" });
      const { data } = await createItem({ variables: { type: "ReleaseItem", itemCreateReleaseInput: { discogsId: id } } });
      close();
      history.push(data?.itemCreate?.path + "?add=true");
    } catch (e: any) {
      setError(e.message);
      addNotification({ ok: 0, message: e.message });
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearchWithDiscogs = async (e: any) => {
    e.preventDefault();
    setSuggestions(null);
    setIsSearching(true);
    const term = (e.target.term?.value || "").trim().toLowerCase();
    try {
      amplitude.track("Search with Discogs", { term });
      let searchResults;
      if (hasDiscogsToken) {
        const { data } = await discogsSearch({ token, term, accessData });
        if (!data?.results) throw new Error("Invalid search results...");
        searchResults = clone(data.results);
      } else {
        const { data } = await backendDiscogsSearch({ variables: { term } });
        if (!data?.discogsSearch?.results) throw new Error("Invalid search results...");
        searchResults = clone(data?.discogsSearch?.results);
      }

      setSuggestions(searchResults);

      const { data: itemStatsData } = await getItemsStats({ variables: { ids: searchResults.map((r: any) => r.id) } });
      const clonedResults = clone(searchResults);

      itemStatsData?.itemStatsForIds?.entries?.forEach((stats: any) => {
        const entry: any = clonedResults.find((r: any) => r.id === stats.id);
        if (entry) entry.stats = stats;
      });
      setSuggestions(clonedResults);
    } catch (e: any) {
      addNotification({ ok: 0, message: `Discogs error: ${e.message || e.data?.message?.toString() || e.toString()}` });
    } finally {
      setIsSearching(false);
    }
  };

  const handleSelectRelease = async (entry: DiscogsRelease) => {
    await handleLoadRelease(entry.id);
  };

  const handleClear = () => {
    setTerm(null);
    setSuggestions(null);
  };

  const handleAddDiscogsId = async (e: any) => {
    e.preventDefault();
    const id = e.target.id.value;
    await handleLoadRelease(id);
  };

  const handleBlockClick = (block: Block) => {
    if (block.path) {
      history.push(block.path);
    } else if (block.id === "book") {
      setStep("book");
    }
  };

  const hasDiscogsToken = token || accessData;

  const blocksArray = blocksWithLabelTranslation();

  if (step === "release")
    return (
      <div id="releaseAdd" className={isMobile ? "mobile" : ""}>
        <ModalHeaderContainer>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography variant="pageTitle" tag="h2">
              {t("Add an item")}
            </Typography>
            <Link className="csvUpload" to="/import">
              <ButtonV2 variant="secondary" styleProps={{ marginLeft: "var(--gutter" }} type="button">
                {t("Import")}
              </ButtonV2>
            </Link>
            <div style={{ marginLeft: "var(--gutter)" }}>
              {isNearInventoryLimit({ config }) ? (
                <ProgressBar
                  value={config.statistics.inventory?.count || 0}
                  label={"Inventory limit"}
                  max={config.plan.features.inventory.limit}
                />
              ) : null}
            </div>
          </div>
          <button className="reset" type="button" onClick={close}>
            <i className="cg-icon-burger-close" />
          </button>
        </ModalHeaderContainer>
        <Zone className="content">
          <div className="container">
            <form onSubmit={handleSearchWithDiscogs}>
              <InputWithSubmit
                label={t("Search through the Discogs database")}
                fullWidth
                autoComplete="off"
                name="term"
                required
                variant="overZone"
                disabled={isSearching || isLoading}
                value={term || ""}
                onChange={(e: any) => setTerm(e.target.value)}
                submitText="Search"
                onFocus={() => localStorage.setItem(`${config.id}-add-release-focus`, "discogs")}
                autoFocus={localStorage.getItem(`${config.id}-add-release-focus`) === "discogs"}
                placeholder={t("Title") + ", " + t("Artist name") + ", " + t("Barcode identifier") + ", " + t("Catno") + "..."}
              />
            </form>
          </div>
        </Zone>
        {isSearching || isLoading ? null : suggestions && !suggestions.length ? (
          <p>{t("No results") + "..."}</p>
        ) : suggestions ? (
          <div className="suggestions">
            {suggestions
              .filter((s: any) => !s.format.includes("File"))
              .map((entry: any, idx: number) => (
                <div key={idx} className="suggestion" onClick={() => handleSelectRelease(entry)}>
                  {entry.thumb ? <img src={entry.thumb} alt={entry.title} /> : null}
                  {entry.stats ? (
                    <p className="stats flexSpaceBetween">
                      <span>
                        {/* eslint-disable-next-line i18next/no-literal-string */}
                        {entry.stats.listingsCount}x {entry.stats.listingsCount ? t("listings") : t("listing")}
                      </span>
                      <span>{entry.stats.audioPreviewsCount ? <i className="cg-icon-player-play" /> : null}</span>
                    </p>
                  ) : null}
                  <p>{entry.title}</p>
                  <p>
                    {clone(entry.label)?.splice(0, 1)} ({entry.catno})
                  </p>
                  <p>{entry.formats?.map((f: any) => `${f.qty}x${f.name} ${f.descriptions?.join(", ")} ${f.text || ""}`)}</p>
                  <p>
                    {entry.country} - {entry.year}
                  </p>
                </div>
              ))}
          </div>
        ) : null}
        {suggestions && !isLoading && !isSearching ? (
          <div className="clear">
            <ButtonV2 type="button" onClick={handleClear} variant="warning">
              {t("Clear all")}
            </ButtonV2>
          </div>
        ) : null}
        {!suggestions && !isSearching ? (
          <div className="addBlocks">
            {blocksArray.map(b => (
              <React.Fragment key={b.id}>
                {b.id === "releaseDiscogs" ? (
                  <Zone className="releaseDiscogs addBlock">
                    {!suggestions && !isSearching ? (
                      <div className="withDiscogsId">
                        <div>
                          <div className="tooltipFlex">
                            <p className="title">{t("With Discogs release ID")}</p>
                            <Tooltip>
                              <p>{t("The release ID [r12345] is located on the top right corner of a Discogs release page")}</p>
                            </Tooltip>
                          </div>
                        </div>
                        <form onSubmit={handleAddDiscogsId}>
                          <InputWithSubmit
                            name="id"
                            variant="overZone"
                            type="text"
                            submitText={isLoading ? <Loader /> : t("Submit")}
                            min="0"
                            value={discogsId}
                            onChange={(e: any) => setDiscogsId(e.target.value.replace(/\D+/g, ""))}
                            onFocus={() => localStorage.setItem(`${config.id}-add-release-focus`, "id")}
                            autoFocus={localStorage.getItem(`${config.id}-add-release-focus`) === "id"}
                            required
                            disabled={isLoading}
                            autoComplete="off"
                            buttonType="submit"
                            placeholder="r12345"
                          />
                        </form>
                      </div>
                    ) : null}
                  </Zone>
                ) : (
                  <BlockButton onClick={() => handleBlockClick(b)} key={b.id} className="addBlock" isMobile={isMobile}>
                    {typeof b.label === "string" ? <p className="title">{b.label}</p> : null}
                  </BlockButton>
                )}
              </React.Fragment>
            ))}
          </div>
        ) : null}
      </div>
    );
  else if (step === "book") return <BookAdder back={() => setStep("release")} addNotification={addNotification} t={t} />;
  else return null;
};

const BookAdder = ({ addNotification, back, t }: { addNotification: any; back: any; t: TFunction }) => {
  const [searchResults, setSearchResults] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const history = useHistory();
  const [searchBook] = useLazyQuery(GET_ITEM_BOOK_SEARCH, { fetchPolicy: "cache-and-network" });
  const [createItem] = useMutation(POST_ITEM_CREATE);

  const handleSearch = async (e: any) => {
    e.preventDefault();
    setIsSearching(true);
    setSearchResults(null);
    try {
      const term = e.target.term.value;
      const { data } = await searchBook({ variables: { term } });
      setSearchResults({ google: clone(data?.itemBookSearch?.google), isbndb: clone(data?.itemBookSearch?.isbndb) });
    } catch (e: any) {
      addNotification({ ok: 0, message: e.toString() });
    } finally {
      setIsSearching(false);
    }
  };

  const handleSelectBook = async (book: any) => {
    if (isLoading) return;
    try {
      setIsLoading(true);
      const descriptions = { short: "", long: book.description };
      amplitude.track("Item created", { type: "Book" });
      const { data } = await createItem({
        variables: {
          type: "BookItem",
          descriptions,
          itemCreateBookInput: {
            title: book.title || "",
            subtitle: book.subtitle || "",
            authors: book.authors || [],
            publisher: book.publisher || "",
            categories: book.categories || [],
            pageCount: parseInt(book.pageCount),
            language: book.language,
            publishedDate: book.publishedDate,
            format: book.format || "",
            weight: parseInt(book.weight) || 100,
            images: removeTypename(book.images || []),
            identifiers: removeTypename(book.identifiers || [])
          }
        }
      });
      history.push(data?.itemCreate?.path + "?add=true");
    } catch (e: any) {
      addNotification({ ok: 0, message: e.message });
    } finally {
      setIsLoading(false);
    }
  };

  const sources: any = { google: "Google Books", isbndb: "ISBN db" };

  return (
    <div id="bookSearchModal">
      <ModalHeaderContainer>
        <div style={{ display: "flex", alignItems: "baseline" }}>
          <Typography variant="pageTitle" tag="h2">
            {t("Book search")}
          </Typography>
          <Button type="button" onClick={back} variant="noStyle">
            {t("Back")}
          </Button>
        </div>
        <ButtonV2 type="link" href={"/item/new?type=BookItem"} variant="secondary">
          {t("Add a book manually")}
        </ButtonV2>
      </ModalHeaderContainer>
      <div className="content">
        <form onSubmit={handleSearch}>
          <InputWithSubmit
            placeholder={t("Search book via ISBN, title, authors") + "..."}
            autoComplete="off"
            fullWidth
            type="text"
            name="term"
            required
            submitText={isSearching ? <Loader style={{ width: "50px" }} /> : "Search"}
            label={t("Search book via ISBN, title, authors") + "..."}
          />
        </form>
        {searchResults ? (
          <div className="results">
            <Tabs className="tabView">
              <TabList className="tabList">
                {searchResults &&
                  Object.keys(searchResults).map(key => (
                    <Tab className={"tab react-tabs__tab"} key={key}>
                      {sources[key]} ({searchResults[key]?.length})
                    </Tab>
                  ))}
              </TabList>
              {searchResults &&
                Object.keys(searchResults).map(key => (
                  <TabPanel key={key} className="entries">
                    {searchResults[key]?.length ? (
                      searchResults[key].map((r: any, idx: number) => (
                        <div className="result" key={`${idx}`} onClick={() => handleSelectBook(r)}>
                          {r.thumb ? <img src={r.thumb} /> : <span />}
                          <p>
                            {r.authors?.length ? r.authors?.join(", ") + ": " : ""}
                            {r.title} {r.subtitle}
                          </p>
                          <p>
                            {r.publisher} {moment(r.publishedDate).format("ll")}
                          </p>
                        </div>
                      ))
                    ) : (
                      <p>{t("No results") + "..."}</p>
                    )}
                  </TabPanel>
                ))}
            </Tabs>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export { AddReleaseLink, AddReleasePage };

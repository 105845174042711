import React, { useEffect, useCallback, Suspense } from "react";
import { Route, Redirect, Switch, useLocation, matchPath } from "react-router-dom";
import "./styles/scss/app.scss";
import "react-tabs/style/react-tabs.css";
import "react-dropdown-tree-select/dist/styles.css";
import "react-tooltip/dist/react-tooltip.css";
import "./styles/scss/common/fonts.css";
import toast, { ToastOptions, Toaster } from "react-hot-toast";
import { ErrorBoundary } from "react-error-boundary";
import * as Sentry from "@sentry/browser";

import Media from "./components/media/media";
import Playlists from "./components/playlists/playlists";
import EditPlaylist from "./components/playlists/edit";
import Jobs from "./components/jobs/jobs";
import TemplateSingle from "./components/templates/single";
import Stickers from "./components/stickers/stickers";
import ItemEdit from "./components/items/edit";
import { AddReleasePage } from "./components/items/add";
import OrderNew from "./components/orders/new";
import Navbar from "./components/global/navbar";
import Menu from "./components/global/menu";
import Player from "./components/global/player";
import Tutorial from "./components/global/tutorial";
import Catalogue from "./components/inventory/catalogue";
import Article from "./components/articles/single";
import StripeConnect from "./components/stripeConnect";
import DiscogsConnect from "./components/discogsConnect";
import Notice from "./components/global/notice";
import Four0Four from "./components/global/404";
import OpenCheckouts from "./components/checkouts/openCheckouts";
import EmailVerify from "./components/emailVerify";
import Announcement from "./components/global/announcement";
import NewsArticles from "./pages/news/newsArticles";
import Version from "./components/version";
import GoogleOauth from "./pages/googleOauth";
import Intro from "./components/intro";
import HelpPanel from "./components/helpPanel";
import useAnalytics from "./hooks/useAnalytics";

import { ConfigContext } from "./contexts/ConfigContext";
import { PlayerContext } from "./contexts/PlayerContext";

import { GlobalStore } from "./stores/global";

import Register from "./pages/register/register";
import { useTheme } from "./hooks/useTheme";
import styled, { ThemeProvider } from "styled-components";

import { useTranslation } from "react-i18next";
import { ApolloClient, NormalizedCacheObject } from "@apollo/client";
import { Typography } from "./componentsV2/Typography";

import { overwrite } from "country-list";
import { colorsAsRgbString } from "@common-ground-io/colors";
import Tests from "./pages/tests";
import { EThemes } from "./types/globals";

const Community = React.lazy(() => import("./pages/community"));
const Account = React.lazy(() => import("./pages/account/account"));
const Profile = React.lazy(() => import("./pages/profile"));
const DiscogsPage = React.lazy(() => import("./pages/discogs"));
const Suppliers = React.lazy(() => import("./pages/suppliers/suppliers"));
const Search = React.lazy(() => import("./pages/search"));
const ThemeEditor = React.lazy(() => import("./pages/eshopEditor/includes/themeEditor"));
const Uploaders = React.lazy(() => import("./pages/uploaders"));
const EditCollection = React.lazy(() => import("./pages/collections/edit"));
const Editor = React.lazy(() => import("./pages/eshopEditor/editor"));
const Campaigns = React.lazy(() => import("./components/campaigns/campaigns"));
const Orders = React.lazy(() => import("./components/orders/orders"));
const Ticket = React.lazy(() => import("./components/support/ticket"));
const Order = React.lazy(() => import("./components/orders/single"));
const Inventory = React.lazy(() => import("./componentsV2/pages/Inventory/inventory"));
const Messages = React.lazy(() => import("./components/messages/messages"));
const Preferences = React.lazy(() => import("./components/preferences/preferences"));
const Item = React.lazy(() => import("./components/items/item"));
const Vouchers = React.lazy(() => import("./components/vouchers/vouchers"));
const CreditNotes = React.lazy(() => import("./pages/creditNotes/creditNotes"));
const Dashboard = React.lazy(() => import("./components/dashboard/dashboard"));
const Templates = React.lazy(() => import("./components/templates/templates"));
const Campaign = React.lazy(() => import("./components/campaigns/campaign"));
const Customers = React.lazy(() => import("./pages/customersAndLists").then(module => ({ default: module.Customers })));
const Customer = React.lazy(() => import("./pages/customersAndLists").then(module => ({ default: module.Customer })));
const Support = React.lazy(() => import("./components/support/support"));
const Articles = React.lazy(() => import("./componentsV2/pages/Articles"));

overwrite([{ code: "GB", name: "United Kingdom" }]);

const App = ({ apolloClient }: { apolloClient: ApolloClient<NormalizedCacheObject> }) => {
  const { isInEditorView, isBelowIpad, menuOverContent, theme, menuIsCollapsed, session } = GlobalStore.useState(c => c);
  const { theme: detectedTheme } = useTheme(window);
  const location = useLocation();
  const isRegisterPage = matchPath(location.pathname, { path: "/register*" });
  const isLoginPage = matchPath(location.pathname, { path: "/login*" });
  const { i18n } = useTranslation();
  useAnalytics();

  const addNotification = useCallback((data: { children?: any; ok: number; message: string }) => {
    const config = {
      iconTheme: {
        primary: "#000",
        secondary: "#fff"
      },
      style: {},
      position: "top-right"
    } as ToastOptions;
    if (data.children) toast.custom(data.children, config);
    else if (data.ok) toast.success(data.message, config);
    else toast.error(data.message, config);
  }, []);

  useEffect(() => {
    if (!detectedTheme) return;
    i18n.changeLanguage(localStorage.getItem("locale") || "en");
    GlobalStore.update(s => {
      s.addNotification = addNotification;
      s.theme = detectedTheme as EThemes;
      s.version = process.env.VITE_VERSION;
    });
  }, [addNotification, detectedTheme]);

  if (session === undefined)
    return (
      <ThemeProvider theme={{ name: theme }}>
        <Intro />
      </ThemeProvider>
    );
  else if (
    session === null ||
    isRegisterPage ||
    isLoginPage ||
    (session && session?.adminDomains?.length === 0) ||
    (session && !session.user.verification?.status)
  )
    return (
      <ThemeProvider theme={{ name: theme }}>
        <Toaster />
        <Version />
        <AppStyled id="app" className={`theme-${theme}`}>
          <Register isRegister={!!isRegisterPage} isLogin={!!isLoginPage} />
        </AppStyled>
      </ThemeProvider>
    );

  return (
    <ThemeProvider theme={{ name: theme }}>
      <ConfigContext apolloClient={apolloClient}>
        <PlayerContext />
        <AppStyled
          id="app"
          className={`theme-${theme} ${isInEditorView ? "isInEditorView" : ""} ${menuIsCollapsed ? "menuIsCollapsed" : ""} ${
            menuOverContent ? "menuOverContent" : ""
          } ${isBelowIpad ? "mobile" : ""}`}>
          <Toaster />
          <Navbar />
          <Menu />
          <div id="mainContent">
            <Player />
            <Notice />
            <Announcement />
            <Version />
            <Tutorial />
            <main id="content">
              <ErrorBoundary FallbackComponent={FallbackRender}>
                <Suspense fallback={<div style={{ textAlign: "center", margin: "10%" }}></div>}>
                  <Switch>
                    <LimitedRoute exact path="/" component={Dashboard} />
                    <LimitedRoute exact path="/dashboard" component={Dashboard} />
                    <LimitedRoute exact path="/search" component={Search} />
                    <LimitedRoute exact path={["/users", "/customers/:id?"]} component={Customers} />
                    <LimitedRoute exact path="/tests" component={Tests} />
                    <LimitedRoute exact path="/playlists" component={Playlists} />
                    <LimitedRoute exact path="/playlist/:id/edit" component={EditPlaylist} />
                    <LimitedRoute exact path="/collection/:id/edit" component={EditCollection} />
                    <LimitedRoute exact new={true} path="/playlist/new" component={EditPlaylist} />
                    <LimitedRoute path="/article/:id/edit" component={Article} />
                    <LimitedRoute exact path="/articles" component={Articles} />
                    <LimitedRoute exact path="/news" component={NewsArticles} />
                    <LimitedRoute exact path={["/user/:id", "/customer/:id"]} component={Customer} />
                    <LimitedRoute path="/preferences/:id?" component={Preferences} />
                    <LimitedRoute exact path="/account/:section?" component={Account} />
                    <LimitedRoute exact key="vouchers" path="/vouchers" component={Vouchers} />
                    <LimitedRoute exact path="/media" component={Media} />
                    <LimitedRoute exact path="/messages" component={Messages} />
                    <LimitedRoute exact path="/jobs" component={Jobs} />
                    <LimitedRoute exact path="/template/new" component={TemplateSingle} />
                    <LimitedRoute path="/template/:id/edit" component={TemplateSingle} />
                    <LimitedRoute exact path="/templates/:id?" component={Templates} />
                    <LimitedRoute exact path={["/newsletters", "/campaigns"]} component={Campaigns} />
                    <LimitedRoute exact path="/campaign/:id" component={Campaign} />
                    <LimitedRoute exact path="/stickers" component={Stickers} />
                    <LimitedRoute exact path="/release/add" component={AddReleasePage} />
                    <LimitedRoute exact path="/inventory" component={Inventory} />
                    <LimitedRoute exact path="/inventory/suppliers" component={Suppliers} />

                    <Route path={["/inventory/discogs/:id?", "/inventory/import"]}>
                      <Redirect to="/discogs" />
                    </Route>
                    <LimitedRoute exact path="/discogs/:id?" component={DiscogsPage} />

                    <LimitedRoute exact path="/import/:id" component={Uploaders} />
                    <LimitedRoute exact path="/import" component={Uploaders} />
                    <LimitedRoute exact path="/inventory/upload" component={Uploaders} />
                    <LimitedRoute exact path="/item/:id/edit" component={ItemEdit} />
                    <LimitedRoute exact path="/orders" component={Orders} />
                    <LimitedRoute exact path="/creditnotes" component={CreditNotes} />

                    <LimitedRoute exact path="/editor/theme/:ref/:section?" component={ThemeEditor} />

                    <LimitedRoute exact path="/editor/*" component={Editor} />
                    <LimitedRoute exact path="/item/new" component={ItemEdit} />
                    <LimitedRoute
                      path={["/item/:id/:title?", "/release/:id/:title?", "/product/:id/:title?", "/book/:id/:title?"]}
                      component={Item}
                    />
                    <LimitedRoute path="/catalogue" component={Catalogue} />
                    <LimitedRoute exact path="/order/new" component={OrderNew} />
                    <LimitedRoute path="/order/:id/" component={Order} />
                    <LimitedRoute exact path="/support" component={Support} />
                    <LimitedRoute exact path="/support/ticket/:id" component={Ticket} />
                    <LimitedRoute exact path="/stripe/connect" component={StripeConnect} />
                    <LimitedRoute exact path="/checkouts/open" component={OpenCheckouts} />
                    <LimitedRoute exact path="/discogs/connect" component={DiscogsConnect} />
                    <LimitedRoute exact path="/email/verify/:state" component={EmailVerify} />
                    <LimitedRoute exact path="/google/oauth" component={GoogleOauth} />
                    <LimitedRoute exact path="/community" component={Community} />
                    <LimitedRoute path="/profile" component={Profile} />
                    <LimitedRoute path="*" component={Four0Four} />
                  </Switch>
                </Suspense>
              </ErrorBoundary>
              <div id="portal" />
            </main>
          </div>
          <HelpPanel />
        </AppStyled>
      </ConfigContext>
    </ThemeProvider>
  );
};

const AppStyled = styled.div`
  background-color: ${({ theme }) => (theme.name === "light" ? colorsAsRgbString.greyLightest : colorsAsRgbString.greyDarkest)};
  color: ${({ theme }) => (theme.name === "light" ? colorsAsRgbString.greyOvercast : colorsAsRgbString.grey)};
`;

const LimitedRoute = (props: any) => {
  const config = GlobalStore.useState(c => c.config);
  return (
    <Route
      {...props}
      component={null}
      render={({ location }) =>
        !config?.status?.active && location.pathname !== "/preferences/billing" ? (
          <Redirect to={{ pathname: "/preferences/billing", state: { from: location } }} />
        ) : (
          <props.component match={props.computedMatch || props.match} location={props.location} path={props.path} />
        )
      }
    />
  );
};

const FallbackRender = ({ error }: { error: any }) => {
  const { t } = useTranslation();

  useEffect(() => {
    Sentry.captureException(error);
  }, [error]);

  return (
    <div style={{ display: "grid", gridGap: "var(--gutter)", marginTop: "10%", justifyItems: "center" }}>
      <Typography variant="pageTitle" level="highlight">
        {t("Something went wrong, please reload this page")}
      </Typography>
      <Typography variant="copy" level="warning">
        {error.message}
      </Typography>
      <Typography variant="copy" level="normal">
        {t("A report was automatically sent to the Common Ground team")}
      </Typography>
    </div>
  );
};

export default App;

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const QuillEditor = ({ onChange, content }: { dependancies?: any; onChange: (value: string) => void; content: string }) => {
  const theme = "snow";

  const modules = {
    toolbar: [["bold", "italic", "underline", "strike"], [{ header: [1, 2, 3, false] }], ["link", "blockquote"], ["clean"]]
  };
  const formats = ["bold", "italic", "underline", "strike", "header", "link", "blockquote", "clean"];

  return (
    <ReactQuill
      modules={modules}
      formats={formats}
      theme={theme}
      className="quillEditor"
      value={content}
      onChange={value => onChange(value)}
    />
  );
};

export default QuillEditor;

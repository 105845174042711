import { useState, useRef } from "react";
import { Button } from "../../../componentsV2/Button";
import Zone from "../../styled/zone";
import Loader from "../../common/loader";
import Liner from "../../common/liner";
import { useStripe, Elements } from "@stripe/react-stripe-js";
import { PaymentIntent, loadStripe } from "@stripe/stripe-js";
import moment from "moment";
import Modal from "../../modal";
import { useMutation, useQuery } from "@apollo/client";
import { GET_INVOICES, POST_INVOICE_CHARGE } from "../../../graphql/queries/invoice";
import * as Sentry from "@sentry/react";
import { GET_CUSTOMER, POST_CONFIG_PLAN_UPDATE, POST_STRIPE_PAYMENT_METHOD } from "../../../graphql/queries/config";
import { GlobalStore } from "../../../stores/global";
import IbanForm from "./includes/ibanForm";
import CardForm from "./includes/card";
import { InvoicesPagination } from "../../common/pagination";
import BlockedAccount from "./blocked";
import {
  Config,
  ConfigStripeUser,
  Invoice,
  InvoicesConnection,
  ConfigStripUserPaymentMethod,
  Plan,
  ConfigAdmin
} from "../../../__generated__/graphql";
import { AddNotification } from "../../../types/globals";
import Termination from "./includes/termination";
import { useTranslation } from "react-i18next";
import { GET_PLANS } from "../../../graphql/queries/plan";
import { getFormattedPrice } from "../../../utils";
import { PriceInConfiCurrency } from "../../common/price";
import { ModalHeaderContainer } from "../../../componentsV2/SectionHeader/SectionHeader.styles";
import { Typography } from "../../../componentsV2/Typography";
import { Checkbox } from "../../../componentsV2/Checkbox";
import clone from "clone";
import { colorsAsRgbString } from "@common-ground-io/colors";
import numeral from "numeral";
import { Link } from "react-router-dom";
import styled from "styled-components";

const stripePromise = loadStripe(process.env.VITE_STRIPE_PUBLISHABLE as string);

const Membership = () => {
  const { config, addNotification, isBelowIpad, configReload, logout, helpPanel } = GlobalStore.useState(c => c);
  const { data } = useQuery(GET_CUSTOMER, { fetchPolicy: "cache-and-network" });
  const modalRef = useRef<any>();
  const [planToUpdate, setPlanToUpdate] = useState<Plan | null>(null);

  const { data: plansData } = useQuery(GET_PLANS, {
    variables: { currencies: [(config as Config).plan.currency] },
    fetchPolicy: "cache-and-network"
  });
  const customer = data && data.configStripeUser;
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const { data: invoicesData, refetch: refetchInvoices } = useQuery(GET_INVOICES, {
    fetchPolicy: "cache-and-network",
    variables: { page, limit: 8 }
  });
  const invoices = invoicesData?.invoices;
  const hasOutstandingInvoices = !!invoices?.hasOutstandingInvoices;

  const nextPaymentDate = config?.plan && config.plan.nextPaymentDate;

  const defaultSource = customer && customer.defaultPaymentId;
  const defaultPaymentMethod = defaultSource && customer.paymentMethods?.find(pm => pm.id === defaultSource);
  const names = { card: "Card", sepa_debit: "SEPA Debit" };

  const handleMembershipStarted = async () => {
    configReload();
    refetchInvoices();
  };

  const plans = plansData?.plans?.plans || [];

  if (!config) return null;

  return (
    <div className="membership">
      <Modal style={{ minWidth: isBelowIpad ? "80vw" : "50vw", minHeight: "40%" }} ref={modalRef}>
        {planToUpdate ? (
          <PlanChange
            config={config}
            isBelowIpad={isBelowIpad}
            plan={planToUpdate}
            addNotification={addNotification}
            close={() => modalRef.current.close()}
          />
        ) : null}
      </Modal>
      {!config?.status?.active && plans.length ? (
        <BlockedAccount
          handleReload={handleMembershipStarted}
          customer={customer as ConfigStripeUser}
          plans={plans}
          hasOutstandingInvoices={hasOutstandingInvoices}
        />
      ) : null}
      <Zone>
        <div className="header">
          <h2 className="text-l-medium">{t("Subscription plans")}</h2>
          <p>
            {t("Started on")} {moment(config?.created).format("ll")} ({moment(config?.created).fromNow()})
          </p>
        </div>
        <div className="plans">
          {plans.map(p => {
            const active = p.title === config?.plan.title;
            const seats = active ? config?.plan?.seats : p.seats;
            return (
              <Zone inverted key={p._id} className={`plan ${active ? "active" : ""}`}>
                <div>
                  <div className="title">
                    <h2>{p.title}</h2>
                    <p>
                      {seats} {(seats || 0) > 1 ? t("seats") : t("seat")}
                    </p>
                  </div>
                  <div className="price">
                    <h2>
                      {getFormattedPrice({
                        value: (active ? config?.plan.price.value : p.price.value) || 0,
                        config: config as Config
                      })}
                    </h2>
                    {p.trial ? <p>{t("Includes a {{daysOfTrial}} days free trial", { daysOfTrial: p.trial.days })}</p> : null}
                  </div>
                </div>
                {active ? (
                  <ProgressBar
                    value={config.statistics.inventory?.count || 0}
                    label={t("Inventory limit")}
                    max={config.plan.features.inventory.limit}
                  />
                ) : null}
                {!active && config?.status.active ? (
                  <div style={{ display: "flex", justifyContent: "left", margin: "20px 0" }}>
                    <Button
                      variant="primary"
                      type="button"
                      onClick={() => {
                        setPlanToUpdate(p);
                        modalRef.current.open();
                      }}>
                      {t("Select this plan")}
                    </Button>
                  </div>
                ) : null}
                <p className="description">{p.description}</p>
              </Zone>
            );
          })}
        </div>
      </Zone>
      <Zone className="invoices">
        <div className="nextPaymentDate">
          <h2 className="text-xl">
            {t("Next payment date")}: {nextPaymentDate ? moment(nextPaymentDate).format("ll") : t("Not scheduled")}
          </h2>
          {nextPaymentDate && defaultPaymentMethod ? (
            <p>
              {/* @ts-ignore */}
              {t("We will charge your payment method")}: {names[defaultPaymentMethod.type]}{" "}
              {defaultPaymentMethod.last4 ? `**** ${defaultPaymentMethod.last4}` : ""}
            </p>
          ) : null}
        </div>
        <Invoices
          addNotification={addNotification}
          customer={customer}
          defaultSource={defaultSource}
          configReload={configReload}
          invoices={invoices as InvoicesConnection}
          page={page}
          setPage={setPage}
        />
      </Zone>
      <PaymentMethods addNotification={addNotification} customer={customer} config={config} />
      <Termination config={config as Config} addNotification={addNotification} logout={logout} helpPanel={helpPanel} />
    </div>
  );
};

interface ExtendedAdmin extends ConfigAdmin {
  selected: boolean;
}
const PlanChange = ({
  config,
  plan,
  isBelowIpad,
  addNotification,
  close
}: {
  isBelowIpad: boolean;
  config: Config;
  plan: Plan;
  addNotification: AddNotification;
  close: () => void;
}) => {
  const [updatePlan] = useMutation(POST_CONFIG_PLAN_UPDATE);
  const { t } = useTranslation();
  const adminsToCopy: ExtendedAdmin[] = config.admins.map(a => ({ ...clone(a), selected: a.role === "owner" }));

  const [adminsSelected, setAdminsSelected] = useState<ExtendedAdmin[]>(adminsToCopy);
  const selectedAdmins = adminsSelected.filter(a => a.selected === true);

  const handlePlanUpdate = (e: any) => {
    e.preventDefault();
    if (!plan) return;
    updatePlan({
      variables: { planRef: plan._id, adminsToKeep: adminsSelected.filter(a => a.role !== "owner" && a.selected).map(a => a._id) }
    })
      .then(() => {
        addNotification({ ok: 1, message: t("Your plan was updated") });
        close();
      })
      .catch(e => addNotification({ ok: 0, message: e.message }));
  };

  const handleSelectAdmin = (admin: ExtendedAdmin) => {
    const foundAdmin = adminsSelected.find(a => a._id === admin._id);
    if (!foundAdmin) throw new Error("Not found");

    if (selectedAdmins.length >= plan.seats && !foundAdmin.selected) return;

    foundAdmin.selected = !foundAdmin.selected;

    setAdminsSelected([...adminsSelected]);
  };

  return (
    <div id="payMyInvoiceModalContent">
      <ModalHeaderContainer>
        <Typography variant="pageTitle" tag="h2">
          {t("Plan update")}
        </Typography>
        <button className="reset" type="button" onClick={() => close()}>
          <i className="cg-icon-burger-close" />
        </button>
      </ModalHeaderContainer>
      {plan ? (
        <form onSubmit={handlePlanUpdate}>
          <div style={{ display: "grid", gridTemplateColumns: isBelowIpad ? "1fr" : "1fr 1fr" }}>
            <div style={{ display: "grid", gridTemplateColumns: "1fr", gridGap: "var(--gutter)" }}>
              <div>
                <Typography variant="copy" tag="p">
                  {t("Current plan")}: {config.plan.title}
                </Typography>
                <Typography variant="copy" level="info" tag="p">
                  {t("New plan")}: {plan.title}
                </Typography>
              </div>
              <div>
                <Typography variant="copy" tag="p">
                  {t("Current plan price")}:
                  {getFormattedPrice({
                    value: config.plan.price.value,
                    currency: config.plan.price.currency
                  })}
                </Typography>
                <Typography variant="copy" level={config.plan.price.value < plan.price.value ? "warning" : "info"} tag="p">
                  {t("New plan price")}:
                  {getFormattedPrice({
                    value: plan.price.value,
                    currency: plan.price.currency
                  })}
                </Typography>
              </div>
              <div>
                <Typography variant="copy" tag="p">
                  {t("Current number of seats")}: {config.plan.seats}
                </Typography>
                <Typography variant="copy" level={plan.seats < config.plan.seats ? "warning" : "info"} tag="p">
                  {t("New number of seats")}: {plan.seats}
                </Typography>
              </div>
              <div>
                <Typography variant="copy" level={"normal"} tag="p">
                  {t("Your inventory has {{count}} items", { count: config.statistics.inventory?.count || 0 })}
                </Typography>
                <Typography variant="copy" tag="p">
                  {t("Previous item limit")}: {config.plan.features.inventory.limit}
                </Typography>
                <Typography
                  variant="copy"
                  level={plan.features.inventory.limit < (config.statistics.inventory?.count || 0) ? "warning" : "info"}
                  tag="p">
                  {t("New item limit")}: {plan.features.inventory.limit}
                </Typography>
              </div>
              <div>
                <Typography variant="copy" level={plan.features.campaigns.status ? "info" : "warning"} tag="p">
                  {t("Campaigns")}: {plan.features.campaigns.status ? t("Yes") : t("No")}
                </Typography>
                <Typography variant="copy" level={plan.features.salesChannels.pos.status ? "info" : "warning"} tag="p">
                  {t("Point of sale")}: {plan.features.salesChannels.pos.status ? t("Yes") : t("No")}
                </Typography>
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "column", gap: "var(--gutter)" }}>
              <Typography variant="copy" level={"normal"} tag="p">
                {t("Select team admins to keep")} ({selectedAdmins.length}/{plan.seats})
              </Typography>
              <div>
                <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
                  {adminsSelected.map(a => (
                    <div key={a._id}>
                      <Checkbox
                        checked={a.selected}
                        onClick={() => handleSelectAdmin(a)}
                        disabled={a.role === "owner"}
                        label={a.admin?.name + ` (${a.role})`}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div />
            <div style={{ marginTop: "var(--gutter)" }}>
              <Button variant="primary" type="submit">
                {t("Submit")}
              </Button>
            </div>
          </div>
        </form>
      ) : null}
    </div>
  );
};

const Invoices = ({
  addNotification,
  customer,
  defaultSource,
  configReload,
  invoices,
  setPage,
  page
}: {
  addNotification: AddNotification;
  customer?: ConfigStripeUser;
  defaultSource?: string | null;
  configReload: any;
  invoices: InvoicesConnection;
  setPage: any;
  page: number;
}) => {
  const { t } = useTranslation();

  if (!invoices) return <Loader />;

  return (
    <div className="invoicesTable" id="invoicesTable">
      <div className="header flexSpaceBetween">
        <h2 className="text-l-medium">
          {t("Invoices")} ({invoices.pagination.count})
        </h2>
        <InvoicesPagination pagination={invoices.pagination} onNext={() => setPage(page + 1)} onPrevious={() => setPage(page - 1)} />
      </div>

      <section className="table">
        {invoices?.invoices.map((i, index) => (
          <Liner key={i.id} index={index} className={"invoiceTableEntry"}>
            <p className="date">
              {moment(i.created).format("ll")}
              <span className="fromNow">({moment(i.created).fromNow()})</span>
            </p>
            <p className="description">
              #{i.id}
              <span className="title"> - {i.title || i.note}</span>
            </p>
            <p className={`status ${!i.paid.status && !i.paid.processing ? t("unpaid") : ""}`}>
              {i.paid.status ? (
                <>{t("Paid")}</>
              ) : (
                <>
                  {i.paid.processing ? (
                    <>
                      <br />
                      {t("Processing")}...
                    </>
                  ) : null}
                </>
              )}
            </p>
            <p className="price">
              <PriceInConfiCurrency value={i.totals.grand} currency={i.currency} />
            </p>
            <div className="actions">
              {i.pdf ? (
                <div>
                  <a target="tab" href={i.pdf}>
                    PDF
                    <i className="cg-icon-open-tab" style={{ marginLeft: "5px" }} />
                  </a>
                </div>
              ) : null}
              {!i.paid.status && !i.paid.processing ? (
                <Elements stripe={stripePromise}>
                  <ChargeInvoice
                    invoice={i}
                    addNotification={addNotification}
                    handleInvoiceCharged={configReload}
                    paymentMethods={(customer && customer.paymentMethods) || []}
                    defaultSource={defaultSource}
                  />
                </Elements>
              ) : null}
            </div>
          </Liner>
        ))}
      </section>
    </div>
  );
};

const ChargeInvoice = ({
  invoice,
  handleInvoiceCharged,
  paymentMethods,
  defaultSource,
  addNotification
}: {
  invoice: Invoice;
  handleInvoiceCharged: any;
  paymentMethods: ConfigStripUserPaymentMethod[];
  defaultSource?: string | null;
  addNotification: AddNotification;
}) => {
  const [chargeInvoice] = useMutation(POST_INVOICE_CHARGE);
  const [isCharging, setIsCharging] = useState(false);
  const stripe = useStripe();
  const modalRef = useRef<any>();
  const { t } = useTranslation();

  const handleSubmitPayment = async (e: any) => {
    e.preventDefault();
    console.log(e.target.method);

    if (!e.target.method.value) return;
    setIsCharging(true);
    try {
      const { data } = await chargeInvoice({ variables: { invoiceRef: invoice._id, paymentMethodId: e.target.method.value } });
      if (!data?.invoiceCharge) throw new Error("Error during charge");
      if (data.invoiceCharge.error && data.invoiceCharge.error === "authentication_required") {
        const intent = data.invoiceCharge.intent;
        const results = await stripe?.confirmCardPayment(intent.client_secret, {
          payment_method: intent.last_payment_error.payment_method.id
        });
        if (results?.error) addNotification({ ok: 0, message: results.error.message || "" });
        else if (results?.paymentIntent.status === "succeeded") {
          addNotification({ ok: 1, message: "Invoice was paid successfully" });
          window.location.reload();
        }
      } else if (data.invoiceCharge.ok) addNotification({ ok: 1, message: data.invoiceCharge.message || "" });
      await new Promise(resolve => setTimeout(resolve, 2000));
      await handleInvoiceCharged();
    } catch (e: any) {
      Sentry.captureException(e);
      addNotification({ ok: 0, message: e.message });
    } finally {
      setIsCharging(false);
    }
  };
  const totalToCharge = invoice.totals;

  return (
    <div className="setAsPaid">
      <Modal style={{}} ref={modalRef}>
        <div id="payMyInvoiceModalContent">
          <ModalHeaderContainer>
            <Typography variant="pageTitle" tag="h2">
              {t("Invoice payment")} #{invoice.id}
            </Typography>
            <button className="reset" type="button" onClick={() => modalRef.current.close()}>
              <i className="cg-icon-burger-close" />
            </button>
          </ModalHeaderContainer>

          {!paymentMethods || paymentMethods.length === 0 ? (
            <div className="noPaymentMethod">
              <p>
                {t("You have not added any payment method yet. Please add a payment method in order to process the payment", {
                  interpolation: { escapeValue: false }
                })}
                .
              </p>
              <Button variant="primary" type="button" onClick={() => modalRef.current.close()}>
                {t("Add a payment method")}
              </Button>
            </div>
          ) : (
            <form onSubmit={handleSubmitPayment}>
              <p>{t("Select your preferred payment method below")}:</p>
              {paymentMethods.map(p => (
                <label key={p.id}>
                  <input
                    type="radio"
                    name="method"
                    required
                    placeholder={t("Enter a payment method")}
                    value={p.id}
                    defaultChecked={p.id === defaultSource}
                  />
                  {/* eslint-disable-next-line i18next/no-literal-string */}
                  {"*** **** ****"} {p.last4} {p.id === defaultSource ? ` - ${t("Default")}` : ""}
                </label>
              ))}
              <div className="total">
                <p>
                  {t("Payable total for this invoice is")} <PriceInConfiCurrency value={totalToCharge.grand} currency={invoice.currency} />
                </p>
              </div>
              <Button variant="primary" type="submit" disabled={!paymentMethods || paymentMethods.length === 0 || isCharging}>
                {isCharging ? <Loader /> : t("Submit payment")}
              </Button>
            </form>
          )}
        </div>
      </Modal>
      <Button variant="primary" onClick={() => modalRef.current.open()}>
        {t("Pay my invoice")}
      </Button>
    </div>
  );
};

export const PaymentMethods = ({
  addNotification,
  customer,
  config
}: {
  addNotification: AddNotification;
  customer?: ConfigStripeUser;
  config: Config;
}) => {
  const [methodBeingAdded, setMethodBeingAdded] = useState<any>(null);
  const [isWorking, setIsWorking] = useState(false);
  const [stripePaymentMethodUpdate] = useMutation(POST_STRIPE_PAYMENT_METHOD);
  const sources = customer && customer.paymentMethods;
  const defaultSource = customer && customer.defaultPaymentId;
  const { t } = useTranslation();
  const modalRef = useRef<any>();

  const handleCompleteSetup = async (intent: PaymentIntent) => {
    if (!intent.payment_method) return;
    try {
      await stripePaymentMethodUpdate({ variables: { attachPaymentMethodId: intent.payment_method.toString() } });
      addNotification({ ok: 1, message: "Payment method added" });
      setMethodBeingAdded(null);
      modalRef.current.close();
    } catch (e: any) {
      addNotification({ ok: 0, message: e.message });
    }
  };

  const handleSetAsDefault = async (paymentMethodId: string) => {
    setIsWorking(true);
    try {
      await stripePaymentMethodUpdate({ variables: { defaultPaymentMethodId: paymentMethodId } });
      addNotification({ ok: 1, message: "Payment method set as default" });
    } catch (e: any) {
      addNotification({ ok: 0, message: e.data });
    } finally {
      setIsWorking(false);
    }
  };

  const handleDeleteMethod = async (paymentMethodId: string) => {
    setIsWorking(true);
    try {
      await stripePaymentMethodUpdate({ variables: { detachPaymentMethodId: paymentMethodId } });
      addNotification({ ok: 1, message: "Payment method removed" });
    } catch (e: any) {
      addNotification({ ok: 0, message: e.message });
    } finally {
      setIsWorking(false);
    }
  };

  if (customer === undefined) return <Loader />;
  else if (customer === null) return <p>{t("No Stripe account found")}</p>;

  const names = { card: t("Card"), sepa_debit: t("SEPA Debit") };
  const hasNoMethod = !sources || sources.length === 0;

  if (hasNoMethod && !methodBeingAdded)
    return (
      <div id="paymentMethods" className={"paymentMethods hasNoMethod"}>
        <div className="header">
          <div className="left">
            <h2 className="text-l-medium">{t("Your payment methods")}</h2>
          </div>
          <div className="right">
            <Button variant="warning" onClick={() => setMethodBeingAdded("card")}>
              {t("Add a Bank Card")}
            </Button>
            <Button variant="warning" onClick={() => setMethodBeingAdded("sepa")}>
              {t("Setup SEPA")}
            </Button>
          </div>
        </div>
        <div className="hasNoMethod">
          <h3>
            {t("You have not added a payment method. Please add a bank card or configure SEPA to keep your account active", {
              interpolation: { escapeValue: false }
            })}
            .
          </h3>
        </div>
      </div>
    );

  return (
    <Zone id="paymentMethods" className={`paymentMethods ${hasNoMethod ? "hasNoMethod" : ""}`}>
      <Modal ref={modalRef} style={{ maxWidth: "50%" }}>
        <ModalHeaderContainer>
          <Typography variant="pageTitle" tag="h2">
            {methodBeingAdded === "sepa" ? `${t("Setup SEPA")} (${config.taxes.country.name})` : t("Add a Bank Card")}
          </Typography>
          <button className="reset" type="button" onClick={() => modalRef.current.close()}>
            <i className="cg-icon-burger-close" />
          </button>
        </ModalHeaderContainer>
        <div id="stripeElements">
          <Elements stripe={stripePromise}>
            {methodBeingAdded === "card" ? <CardForm addNotification={addNotification} onFinish={handleCompleteSetup} /> : null}
            {methodBeingAdded === "sepa" ? (
              <IbanForm config={config} addNotification={addNotification} onFinish={handleCompleteSetup} />
            ) : null}
          </Elements>
        </div>
      </Modal>
      <div className="header">
        <div className="left">
          <h2 className="text-l-medium">{t("Your payment methods")}</h2>
        </div>
        <div className="right">
          <Button
            variant="secondary"
            onClick={() => {
              setMethodBeingAdded("card");
              modalRef.current.open();
            }}>
            {t("Add a Bank Card")}
          </Button>
          <Button
            variant="secondary"
            onClick={() => {
              setMethodBeingAdded("sepa");
              modalRef.current.open();
            }}>
            {t("Setup SEPA")}
          </Button>
        </div>
      </div>
      <div className="content">
        <div className="sources">
          {sources?.map(s => (
            <div key={s.id} className={`source ${s.id === defaultSource ? t("Default") : ""}`}>
              <p>{s.name || s.type}</p>
              {/* eslint-disable-next-line i18next/no-literal-string */}
              <p className="last4"> *** **** **** {s.last4}</p>
              {/* @ts-ignore */}
              <p className="type">{s.type === "card" ? s.brand?.toUpperCase() : names[s.type]}</p>
              <p className="expires">{s.type === "card" ? `Expires: ${s.expMonth + "/" + s.expYear}` : ""}</p>
              {s.id !== defaultSource ? (
                <div className="delete">
                  <Button
                    type="button"
                    disabled={isWorking || s.id === defaultSource}
                    variant="warning"
                    onClick={() => window.confirm(t("Are you sure?")) && handleDeleteMethod(s.id)}>
                    {t("Delete")}
                  </Button>
                </div>
              ) : (
                <span />
              )}
              {s.id === defaultSource ? (
                <div className="default">
                  <Button type="button" variant="secondary" disabled>
                    {t("Default")}
                  </Button>
                </div>
              ) : (
                <div className="default">
                  <Button type="button" variant="secondary" disabled={isWorking} onClick={() => handleSetAsDefault(s.id)}>
                    {t("Make default")}
                  </Button>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </Zone>
  );
};

const ProgressBackround = styled.div`
  width: 100%;
  height: 6px;
  background-color: ${props => (props.theme.name === "dark" ? colorsAsRgbString.greyDark : colorsAsRgbString.greyLighter)};
  border-radius: 3px;
`;
export const ProgressBar = ({ value, max, label }: { value: number; max: number; label: string }) => {
  const ratio = value / max;
  const level = ratio > 0.75 ? "warning" : "success";
  const progressColor = level === "warning" ? colorsAsRgbString.alert : colorsAsRgbString.success;

  return (
    <div style={{ minWidth: "220px" }}>
      <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "5px" }}>
        <Link to="/preferences/billing">
          <Typography variant="copy" level="secondary">
            {label}
          </Typography>
        </Link>
        <div>
          <Typography variant="copy" level={level}>
            {value} / {numeral(max).format("0a")}
          </Typography>
        </div>
      </div>
      <ProgressBackround>
        <div style={{ width: `${Math.min(ratio * 100, 100)}%`, height: "6px", backgroundColor: progressColor, borderRadius: "3px" }}></div>
      </ProgressBackround>
    </div>
  );
};

export default Membership;
